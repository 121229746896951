<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="안전작업표준 작업명" :value="param.sopName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="사업장" :value="param.plantName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="단위공정" :value="param.processName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="안전작업표준 관리번호" :value="param.sopNo"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-label-text title="작업구분" :value="param.workClassName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-label-text title="작업유형" :value="param.workTypeName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-label-text title="작업형태" :value="param.workKindName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-label-text title="위험등급" :value="param.hazardGradeName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
            <c-label-text title="제(개)정번호" :value="param.revisionNum"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
            <c-label-text title="제(개)정자" :value="param.regUserName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-label-text title="제(개)정일시" :value="param.regDtStr"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="안전작업표준 작업절차"
        tableId="riskHazrd02"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :columns="grid.columns"
        :data="grid.data"
        selection="multiple"
        rowKey="jobStepId"
      >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="작업단계 추가" icon="add" @btnClicked="addrow" />
              <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveStep" />
              <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleteStep" />
            </q-btn-group>
          </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'sop-step',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmSopId: '',
        plantName: '',
        sopName: '',
        sopNo: '',
        processName: '',
        hazardGradeName: '',
        workTypeName: '',
        workKindName: '',
        workClassName: '',
        revisionNum: '',
        regDtStr: '',
        regUserName: '',
        isRevPop: false,
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'jobStepNo',
            field: 'jobStepNo',
            label: '순번',
            style: 'width:50px',
            type: 'number',
            align: 'center',
            sortable: false,
          },
          {
            name: 'jobStepName',
            field: 'jobStepName',
            label: '작업단계 명',
            style: 'width:15%',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'jobStepDesc',
            field: 'jobStepDesc',
            label: '작업단계 설명',
            align: 'left',
            type: 'textarea',
            style: 'width:20%',
            sortable: false,
          },
          {
            name: 'saftyWorkMethod',
            field: 'saftyWorkMethod',
            label: '안전작업방법',
            align: 'left',
            type: 'textarea',
            style: 'width:20%',
            sortable: false,
          },
          {
            name: 'saftyShied',
            field: 'saftyShied',
            label: '안전보호구',
            align: 'left',
            type: 'textarea',
            style: 'width:15%',
            sortable: false,
          },
          {
            name: 'useTool',
            field: 'useTool',
            label: '사용공구',
            align: 'left',
            type: 'textarea',
            style: 'width:15%',
            sortable: false,
          },
          {
            name: 'caution',
            field: 'caution',
            label: '주의사항',
            align: 'left',
            type: 'textarea',
            style: 'width:15%',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  watch: {
    param() {
      this.getList()
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.param.isRevPop) {
        this.editable = false;
      }
      // url setting
      this.listUrl = selectConfig.mdm.sop.step.url;
      this.saveUrl = transactionConfig.mdm.sop.step.save.url;
      this.deleteUrl = transactionConfig.mdm.sop.step.delete.url;
      this.getList();
    },
    getList() {
      if (this.param.mdmSopId) {
        this.$http.url = this.$format(this.listUrl, this.param.mdmSopId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    saveStep() {
      let checkItem = ['jobStepNo', 'jobStepName', 'jobStepDesc']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [코드, 코드명, 순번]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList();
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addrow() {
      this.grid.data.push({
        editFlag: 'C',
        jobStepId: '',
        mdmSopId: this.param.mdmSopId,
        jobStepNo: '',
        jobStepName: '',
        jobStepDesc: '',
        saftyWorkMethod: '',
        saftyShied: '',
        useTool: '',
        caution: '',
        useFlag: 'Y',
      })
    },
    deleteStep() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>